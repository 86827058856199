'use strict';

angular.module('enervexSalesappApp').service('DesignService', function(Design, DesignMultipart, DesignComment, Attachment, $stateParams, _) {
	this.fetchDesigns = fetchDesigns;
	this.fetchDesignVersions = fetchDesignVersions;
	this.getDesign = getDesign;
	this.newDesign = newDesign;
	this.updateDesignDetails = updateDesignDetails;
	this.updateDesign = updateDesign;
	this.deleteDesign = deleteDesign;
	this.getComments = getComments;
	this.createComment = createComment;
	this.removeComment = removeComment;
	this.updateComment = updateComment;
	this.createAttachment = createAttachment;
	this.calculate = calculate;
	this.getBOM = getBOM;

	function fetchDesigns() {
		return Design.query({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId
		});
	}

	function fetchDesignVersions() {
		return Design.versions({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			id: $stateParams.designId
		});
	}
	function fetchDesignVersion() {
		return Design.version({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			id: $stateParams.designId,
			version: $stateParams.version
		});
	}
	function getBOM(design) {
		return Design.bom({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			id: $stateParams.designId
		}, design);
	}

	function calculate(design) {
		return Design.calculation({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			id: $stateParams.designId
		}, design);
	}

	function getDesign(designID) {
		if ($stateParams.designId === undefined) {
			$stateParams.designId = designID.design;
		}
		if ($stateParams.version){
			return fetchDesignVersion()
		} else {
			return Design.get({
				accountId: $stateParams.accountId,
				jobId: $stateParams.jobId,
				id: $stateParams.designId
			});
		}
	}

	function newDesign(design) {
		var payload = _.extend({}, design);
		return Design.save({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId
		}, payload);
	}

	function updateDesignDetails(design) {
		return Design.update({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			id: $stateParams.designId
		}, design);
	}

	function updateDesign(design, photo) {
		//console.log('final design copy', design)
		if (photo) {
			var fd = new FormData();
			fd.append('photo', photo);
			var cleaned = _.omit(design, "photo", "updatedBy", "createdBy", "__v", "toJSON", 'deleted');
			_.each(_.keys(cleaned),function(key){
				if (key.indexOf('$')==0){
					return;
				}
				var value = design[key];
				if (_.isObject(value)) {
					var json = angular.toJson(value);
					fd.append(key, json);
				} else {
					fd.append(key, value);
				}
			})
			return DesignMultipart.update({
				accountId: $stateParams.accountId,
				jobId: $stateParams.jobId,
				id: $stateParams.designId
			}, fd);
		} else {
			return Design.update({
				accountId: $stateParams.accountId,
				jobId: $stateParams.jobId,
				id: $stateParams.designId
			}, design);
		}
	}

	function deleteDesign(design) {
		return Design.remove({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			id: design._id
		});
	}

	function getComments() {
		return DesignComment.query({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			designId: $stateParams.designId,
		});
	}

	function createComment(newComment) {
		return DesignComment.save({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			designId: $stateParams.designId
		}, newComment);
	}

	function removeComment(comment) {
		return DesignComment.remove({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			designId: $stateParams.designId,
			id: comment._id
		});
	}

	function updateComment(comment) {
		return DesignComment.update({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			designId: $stateParams.designId,
			id: comment._id
		}, comment);
	}

	function createAttachment(fd) {
		return Attachment.save({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId
		}, fd);
	}
});
